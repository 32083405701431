import { mapGetters, mapActions } from 'vuex'
import cookies from 'js-cookie'
import dayjs from 'dayjs'
export default {
  components: {
    TheHeader: () => import(/* webpackChunkName: "the-header" */ '@/containers/TheHeader'),
    TheFooter: () => import(/* webpackChunkName: "the-footer" */ '@/containers/TheFooter'),
    TheMobileHeader: () => import(/* webpackChunkName: "the-mobile-heder" */ '@/containers/TheMobileHeader'),
    TheSidebar: () => import(/* webpackChunkName: "the-sidebar" */ '@/containers/TheSidebar'),
    TheNavBottom: () => import(/* webpackChunkName: "the-navbottom" */ '@/containers/TheNavBottom'),
    AdminChat: () => import(/* webpackChunkName: "admin-chat" */ '@/components/chat/AdminChat'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    FullScreenLoading: () => import(/* webpackChunkName: "illustration-loading-full" */ '@/components/illustration/FullScreenLoading'),
    Dropdown: () => import(/* webpackChunkName: "DropDown" */ '@/components/dropdown/Dropdown')
  },
  data: () => ({
    noRerenderPage: ['/login', '/register'],
    fullPages: ['/partner-profile', '/clouducation', '/clouducation/', '/clouducation/thankyou'],
    isPopUpAdsVisible: null,
    popUpContent: {
      title: '',
      description: '',
      image: '',
      primaryBtn: '',
      secondaryBtn: '',
      path: '',
      startDate: '',
      endDate: ''
    },
    userArea: ['/user/profile', '/user/my-classes', '/user/change-password', '/user/account'],
    isMultilanguangeVisible: false,
    languangeOptions: [
      {
        label: 'English',
        code: 'en'
      },
      {
        label: 'Bahasa Indonesia',
        code: 'id'
      }
    ],
    selectedLanguange: {
      label: 'English',
      code: 'en'
    },
    footerHidden: ['/help']
  }),
  computed: {
    ...mapGetters('common', ['isMobile', 'loading', 'isNavOpen']),
    ...mapGetters('auth', ['isUnauthorized', 'userData', 'isExpired']),
    currentLanguange() {
      const lang = localStorage.getItem('languange')
      return lang
    },
    baseDomain() {
      return process.env.VUE_APP_BASE_DOMAIN
    },
    userRoles() {
      return this.userData.role_id
    },
    isHomePage() {
      return this.$route.name === 'Home'
    },
    isDashboardPage() {
      return this.$route.meta.page === 'dashboard'
    },
    pageName() {
      return this.$route.name
    },
    classCategory() {
      return this.$route.params.category
    },
    utm() {
      return this.$route.query.utm_source
    },
    adsVisited() {
      return cookies.get('adsVisited', { domain: this.baseDomain, expires: 365 })
    },
    isHiddenChatButton() {
      return (this.utm && this.classCategory === 'bootcamp') || this.isNavOpen || (this.$route.meta?.hideWa && this.$route.meta?.hideWa === true)
    },
    checkDuration() {
      if (this.popUpContent.startDate === '' || this.popUpContent.endDate === '') return null
      return this.checkDifferenceTime(this.popUpContent.startDate, this.popUpContent.endDate)
    },
    durationDifference() {
      if (this.checkDuration === null) return null
      return this.checkDifferenceTime(dayjs(), this.popUpContent.startDate)
    },
    duration() {
      if (this.checkDuration === null || this.checkDuration < this.durationDifference) return null
      const result = this.checkDuration - this.durationDifference
      if (result === 0) return 1
      return result
    }
  },
  created() {
    // if (this.isHomePage) this.checkPopUpContent()
    this.setDefaultLanguange()
  },
  methods: {
    ...mapActions('auth', ['hideUnauthorizedModal', 'hideTokenExpiredModal', 'logoutPrakerja', 'removeUserData']),
    ...mapActions('common', ['showLoading', 'hideLoading', 'toggleSideBar']),
    ...mapActions('home', ['getPopUpContent']),
    setDefaultLanguange() {
      if (!this.currentLanguange) {
        localStorage.setItem('languange', 'en')
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = this.currentLanguange
      }
    },
    setLanguange(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('languange', lang)
      this.toogleMultilanguangeModal()
    },
    toogleMultilanguangeModal() {
      this.isMultilanguangeVisible = !this.isMultilanguangeVisible
    },
    showMultilanguangeModalFromSideBar() {
      this.toggleSideBar()
      this.toogleMultilanguangeModal()
    },
    updateUnauthorizedInfo(target) {
      this.hideLoading()
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserData())
      })
      cleanUserData.then(() => {
        this.hideUnauthorizedModal()
        if (target) {
          this.$router.push({ name: target })
        }
      })
    },
    setAction(target) {
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserData())
      })
      cleanUserData.then(() => {
        this.hideTokenExpiredModal()
        if (target === 'Login') {
          this.$router.push(`/login?ref=${window.location.pathname}`)
        } else {
          this.$router.push({ name: target })
        }
      })
    },
    toSelectedPage() {
      this.isPopUpAdsVisible = false
      cookies.set('adsVisited', 3, { expires: this.duration })
      this.$router.push({ path: this.popUpContent.path })
    },
    addVisitedData() {
      this.isPopUpAdsVisible = false
      let adsVisited = parseInt(this.adsVisited)
      adsVisited++
      cookies.set('adsVisited', adsVisited, { expires: this.duration })
    },

    checkPopUpContent() {
      this.getPopUpContent({}).then(
        (res) => {
          if (res.data.code === 404) return cookies.remove('adsVisited')
          const popUp = res.data.data
          this.popUpContent = {
            title: popUp.title,
            description: popUp.description,
            image: popUp.image,
            primaryBtn: popUp.labelBtn1,
            secondaryBtn: popUp.labelBtn2,
            path: popUp.toUrl,
            startDate: popUp.startDate,
            endDate: popUp.endDate
          }

          this.checkCookie()
        },
        () => {}
      )
    },

    checkDifferenceTime(startDate, endDate) {
      return Math.abs(dayjs(startDate, 'YYYY-MM-DD').startOf('day').diff(dayjs(endDate, 'YYYY-MM-DD').startOf('day'), 'days'))
    },

    checkCookie() {
      if (this.duration === null) return
      const adsVisited = cookies.get('adsVisited', { domain: this.baseDomain })
      if (isNaN(adsVisited)) {
        cookies.set('adsVisited', 0, { expires: this.duration })
      }

      if (!this.isMobile) {
        return
      }
      if (adsVisited >= 2) {
        this.isPopUpAdsVisible = false
      } else {
        this.isPopUpAdsVisible = true
      }
    }
  }
}
